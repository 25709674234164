import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import FeaturedCaseStudies from '../components/FeaturedCaseStudies';
import FeaturedBlogPosts from '../components/FeaturedBlogPosts';

const SectorPageTemplate = ({
  data: { datoCmsSector },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    banner,
    modularBlocks,
    relatedCaseStudiesHeading,
    relatedCaseStudies,
    relatedBlogPostsHeading,
    relatedBlogPosts,
  } = datoCmsSector;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsSector}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
        />
        <ModularBlocks items={modularBlocks} />
        <FeaturedCaseStudies
          heading={relatedCaseStudiesHeading || 'Related Case Studies'}
          items={relatedCaseStudies}
          locale={locale}
        />
        <FeaturedBlogPosts
          heading={relatedBlogPostsHeading || 'Related Blog Posts'}
          items={relatedBlogPosts}
          locale={locale}
        />
      </main>
    </Layout>
  );
};

export const SectorPageTemplateQuery = graphql`
  query SectorPageTemplateQuery($id: String!) {
    datoCmsSector(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...CtaStripModularBlockFragment
        ...ContentBlocksModularBlockFragment
        ...ContentModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageCtaCardsModularBlockFragment
      }
      relatedCaseStudiesHeading
      relatedCaseStudies {
        ...CaseStudyResourceCardFragment
      }
      relatedBlogPostsHeading
      relatedBlogPosts {
        ...BlogResourceCardFragment
      }
      ...LinkFragment
    }
  }
`;

export default SectorPageTemplate;
